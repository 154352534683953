import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/index.vue')
  },
  {
    path: '/payor',
    name: 'payor',
    component: () => import('../views/gm/index')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
